.reservation-border-left {
  position: relative;
  &:after {
    bottom: 0;
    left: 20px;
    content: "";
    display: block;
    background-color: map.get($colors, 'black');
    height: 100%;
    width: 1px;
    position: absolute;
  }
  @include media-breakpoint-down(xl) {
    &:after {
      left: 10px;
    }
  }

  @include media-breakpoint-down(lg) {
    &:after {
      display: none;
    }
  }
}

.reservation-wrapper {
  background-image: url('assets/images/resa-cover-bg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    background-attachment :inherit;
    background-size: cover;
  }

  .form-resa {
    background-color: map.get($colors, 'green');
    border-radius: 10px;
    padding: 30px;
  }
  
  .container {
    padding: 170px 0;
    position: relative;

  }
  
  .car-decoration {
    bottom: -110px;
    right: -150px;
    position: absolute;
    width: 600px;
    z-index: 10;
  }

  @include media-breakpoint-down(xl) {
    .container {
      padding: 130px 0;
    }
    .car-decoration {
      width: 500px;
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      padding: 80px 0;
    }
    .car-decoration {
      display: none;
    }
  }  

  @include media-breakpoint-down(md) {
    .container {
      padding: 50px 0;
    }
  }  

  @include media-breakpoint-down(sm) {
    .container {
      padding: 30px 15px;
    }
    .form-resa {
      padding: 20px 15px;
    }
  }  

}

.form-resa-inner-page {
  .reservation-wrapper {
    background: none !important;
  }
}

