.reservation-car-item {
  background-color: $white;
  border-radius: 10px;
  overflow: hidden;
  

  .img-wrapper {
    display: block;
    padding: 0 15px;
    
    img {
      width: 100%;
      transition: all .1s ease-in-out;
    }
  }

  .price-wrapper {
    display: flex;
    justify-content: end;
    align-items: end;

    .price {
      margin-right: 5px;
      position: relative;
      top: 4px;
    }
  }

  .link {
    display: block;
    color: map.get($colors, "black") !important;
    padding: 30px 0 110px;
    position: relative;
    width: 100%;

    .footer-wrapper {
      bottom: 0;
      position: absolute;
      padding: 0 30px 30px;
      width: 100%;
    }
  
    &:hover {
      img {
        transform: scale(.95);
        opacity: .8;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .link {
      padding-bottom: 70px;
    }
  } 
  
  @include media-breakpoint-down(sm) {
    .link {
      padding-bottom: 90px;
    }
  }
  
  .price {
    font-family: $font-bold;
    @include font-size(26);
  }
}
