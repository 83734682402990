.wysiwyg-wrapper {
  >* {
    &:first-child {
      margin-top: 0 !important;
    }

    *:last-child {
      margin-bottom: 0 !important;
    }
  }

  @for $i from 1 through 3 {
    h#{$i} {
      color: map.get($colors, 'green');
      @extend .title-#{$i};
    }
  }

  h1,
  h2,
  h3,
  h4,
  ol,
  ul {
    margin: 30px 0 35px;
  }

  .headline {
    @extend .title-3;
  }

  p {
    margin-bottom: 15px;
    @extend .text-2;
  }

  .highlight-wrapper {
    border: 2px solid map.get($colors, 'green');
    border-radius: 10px;
    color: map.get($colors, 'black');
    display: block;
    font-family: $font-bold;
    font-style: italic;
    margin: 50px auto;
    padding: 30px;
    width: 75%;
    @extend .title-3;
  }

  ol,
  ul {
    counter-reset: ol-counter;
    padding-left: 30px;

    li {
      display: block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 10px;
      @extend .text-2;
    }
  }

  ul {
    li {
      &:before {
        content: "";
        background-color: map.get($colors, 'green');
        height: 2px;
        left: 5px;
        position: absolute;
        top: 8px;
        width: 8px;
      }
    }
  }

  ol {
    li {
      &:before {
        content: counter(ol-counter)".";
        color: map.get($colors, 'green');
        counter-increment: ol-counter;
        left: 0;
        position: absolute;
        top: 0;
        @extend .text-2;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: map.get($colors, 'black');
    text-decoration: underline;
  }

  .button-wrapper {
    margin: 30px 0;

    a {
      text-decoration: none;
      @extend .button-regular;
    }
  }

  img {
    border-radius: 10px;
    max-width: 100%;
    height: auto;

    &.img-left {
      float: left;
      margin: 5px 30px 15px 0;
    }

    &.img-right {
      float: right;
      margin: 5px 0 15px 30px;
    }

    &.img-center {
      margin: 50px auto;
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    .highlight-wp {
      margin: 20px 0;
      padding: 20px;
      width: 100%;
    }

    h2,
    h3,
    ol,
    ul,
    .highlight-wrapper,
    img,
    .button-wrapper {
      margin: 20px 0 !important;
    }

    img {

      &.img-left,
      &.img-right {
        display: block;
        float: none;
      }
    }
  }
}