$sprites : (
  (scroll-top, 30px, 30px, 0 0),
  (icon-proximity, 30px, 30px, -30px 0),
  (icon-price, 30px, 30px, -60px 0),
  (icon-client, 30px, 30px, -90px 0),
  (icon-clock, 30px, 30px, -120px 0),
  (icon-calendar, 30px, 30px, -150px 0),
  (icon-phone, 30px, 30px, -180px 0),
  (icon-facebook, 30px, 30px, -210px 0),
  (icon-instagram, 30px, 30px, -240px 0),
  (icon-account, 30px, 30px, -270px 0),
  (arrow-prev, 20px, 30px, -300px 0),
  (arrow-next, 20px, 30px, -320px 0),
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }
}
