
#footer {
	background-color: map.get($colors,'black');
	color: $white;

	.header-bottom-wrapper {
		display: none;
		@include media-breakpoint-down(md) {
			background-color: map.get($colors, 'green');
			display: block;
			.header-bottom {
				overflow: inherit;
				display: block;
				
				.item {
					display: block;
					width: 100%;
					margin: 5px 0;
					margin-right: 0;

					.sprite {
						margin: 0 auto;
					}
					
					text-align: center;

				}
			}
		}
	}
	

	.link {
		display: block;
		transition: all .2s ease-in-out;
		&:hover {
			transform: translateX(5px);
			color: map.get($colors, 'green');
		}
	}

	.sprite {
		display: block;
		transition: all .1s ease-in-out;

		&:hover {
			transform: translateY(3px);
		}
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}

	.footer-pixell {
		border-top: 1px solid map.get($colors, 'green');
		margin-top: 30px;
		padding-top: 10px;
		padding-bottom: 20px;
		text-align: center;
		@include media-breakpoint-down(sm) {
			margin-top: 15px;
		}

		.pixell {
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				color: $white;
			}
		}
	}
	
}