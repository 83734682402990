.login-form-wrapper {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 25px;

  .label {
    color: map.get($colors, 'black');
    font-family: $font-bold;
    text-transform: uppercase;
    @include font-size(14);
  }

  .form-control {
    display: block;
    border-radius: 0;
    border-bottom: 1px solid lighten(map.get($colors, 'black'), 60%) !important;
    color: map.get($colors, 'black');
    padding: 6px 0;
    outline: none;
    width: 100%;
    @include font-size(14);
    &::placeholder {
      color: lighten($black, 70%);
    }
  }

  .password {
    color: lighten(map.get($colors, 'black'), 30%);
    display: block;
    font-style: italic;
    text-decoration: underline;
    @include font-size(13);
  }

}