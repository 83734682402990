$colors: (
  'black': #323232,
  'white': #FFFFFF,
  'grey': #CCCCCC,
  'grey-light': #FDFDFD,
  'error': #a94442,
  'green': #89CE00,
  'green-light' : #89ce0040,
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }

}
