#header {
	background-color: map.get($colors, 'black');
	position: relative;
	padding: 0;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);
}

.header-top {
	align-items: center;
	color: $white;
	display: flex;
	justify-content: space-between;
	padding: 25px 0;
	@include media-breakpoint-down(xl) {
		padding: 15px 0;
	}
	@include media-breakpoint-down(sm) {
		.logo {
			width: 70px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 7.5px 0;
		display: block;
	}
}

body {
	&.menu-open {
		.header-top {
			padding-bottom: 0;
		}
	}
}

.header-bottom-wrapper {
	background-color: map.get($colors, 'green');
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.header-bottom {
	align-items: center;
	color: map.get($colors, 'black');
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
	padding: 4px 0;
	position: relative;
	
	@include media-breakpoint-down(md) {
		overflow-x:auto;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 15px;
			height: 100%;
			background: linear-gradient(to right,  rgba(137,206,0,0) 0%,rgba(137,206,0,1) 100%);
		}
	}
	
	.item {
		display: flex;
		align-items: center;
		@include media-breakpoint-down(lg) {
			@include font-size(12);
			line-height: 1.2em;
		}
		@include media-breakpoint-down(md) {
			@include font-size(11);
			&:not(:last-child) {
				margin-right: 15px;
			}
			white-space: nowrap;
		}
	}
}

#navigation-logo-phone {
	display: flex;
	align-items: center;
	
	.link {
		align-items: center;
		color: map.get($colors, 'green');
		display: flex;
		font-family: $font-bold;
		padding-left: 15px;
		transition: all .2s ease-in-out ;
		@include font-size(18);
		
		.sprite {
			transition: all .2s ease-in-out ;
		}

		&:hover {
			color: $white;
			.sprite {
				transform: scale(.85) translateX(5px);
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.link {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		.link {
			display: block;
		}
	}
}

#navigation-main {
	display: flex;
	align-items: center;
	@include media-breakpoint-down(sm) {
		background-color: color.adjust(map.get($colors, 'black'), $lightness: -10%);
		width: calc(100% + 30px);
		padding: 10px 0;
		margin-left: -15px;
		border-top: 2px solid map.get($colors, 'green');
		margin-top: 10px;
	}



	.item {
		flex-grow: 1;
		&:not(:last-child) {
			margin-right: 30px;
			@include media-breakpoint-down(xxl) {
				margin-right: 10px;
			}
			@include media-breakpoint-down(xl) {
				margin-right: 0px;
				&:nth-last-child(2) {
					margin-right: 10px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			margin: 10px 0;
			&:last-child {
				margin: 20px 0;
			}
		}
	}

	.link {
		color: map.get($colors, 'white');
		display: block;
		text-align: center;
		position: relative;
		padding: 15px;
		text-transform: uppercase;
		transition: all .2s ease-in-out ;
		@include font-size(15);

		@include media-breakpoint-down(lg) {
			padding: 10px;
			@include font-size(15);
			line-height: 1.2em;
		}

		@include media-breakpoint-down(md) {
			@include font-size(14);
		}
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			bottom: -5px;
			left: 50%;
			margin-left: -4px;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: map.get($colors, 'green');
			transition: all .2s ease-in-out ;
			opacity: 0;
		}
		
		&:focus,
		&:hover {
			&:not(.account) {
				color:  map.get($colors, 'green');
				&:after {
					bottom: 0;
					opacity: 1;
				}
			}
		}

		&.active {
			color: map.get($colors, 'green');
		}

		&.account {
			align-items: center;
			border-radius: 5px;
			border: 2px solid map.get($colors, 'green');
			display: flex;
			padding: 3px 12px;
			transition: all .2s ease-in-out;
			.txt {
				white-space: nowrap;
			}
			.sprite {
				margin-right: 4px;
				transition: all .1s ease-in-out;
			}
			&:hover {
				background-color: map.get($colors, 'green');
				color: map.get($colors, 'black');
				.sprite {
					background-position: -270px -30px;
				}
			}
			@include media-breakpoint-down(lg) {
				@include font-size(12);
				padding-left: 5px;
				padding-right: 10px;
			}
			@include media-breakpoint-down(md) {
				padding-right: 5px;
				.sprite {
					margin-right: 0;
				}
				.txt { 
					display: none;
				}
			}
			@include media-breakpoint-down(sm) {
				display: flex;
				width: 120px;
				margin: 0 auto;
				.txt { 
					display: block;
				}
			}

		}
	}

	.navigation-sub {
		.item-sub {}

		.link-sub {

			&:focus,
			&:hover {}
	
			&.active {}
	
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

@include media-breakpoint-up(sm) {
	#navigation-main {
		display: flex !important;
	}

	#button--mobile {
		display: none;
	}
}

#button--mobile {
	position: absolute;
	top: 25px;
	right: 15px;
}

.navigation-sub {
	background-color: map.get($colors, 'white');
	display: none;
	height: auto;
	left: auto;
	opacity: 1;
	position: relative;
	top: auto;
}

li {
	&[data-navsub] {
		position: relative;
	}
}

@include media-breakpoint-up(sm) {
	li {
		&[data-navsub] {
			&.focus {
				> .navigation-sub {
					display: block;
					height: auto;
					opacity: 1;
					top: 60px;
					padding: 10px;
					visibility: visible;
				}
			}
		}
	}

	.navigation-sub {
		background-color: map.get($colors, 'white');
		box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.2);
		display: none;
		height: 0;
		left: 0;
		opacity: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 100%;
		z-index: 99;
		@include transition(all, 0.1s, ease-in-out);
	}
}

body {
	&.header-small {
		#header {
			@include media-breakpoint-up(lg) {}
		}
	}
}

#header-page {
	background-image: url('assets/images/resa-cover-bg-inner.jpg');
  background-attachment: cover;
  background-position: center;
  background-repeat: no-repeat;
	background-color: map.get($colors, 'black');
	color: $white;
	padding: 50px 0;
	position: relative;
	text-align: center;

	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}

	.container {
		position: relative;
		z-index: 2;
	}
	&:after {
		content: "";
		background-color: rgba(0,0,0, .4);
	 	position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
