.faq-item {
  color: map.get($colors, 'white');
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: block;
  line-height: 1.2em;
  position: relative;
  text-align: left;
  width: 100%;
  @include transition(all, 0.2s, ease-in-out);

  &--button {
    color: map.get($colors, 'black');
    display: block;
    font-family: $font-bold;
    line-height: 1.3em;
    padding: 18px 60px 20px 25px;
    position: relative;
    text-align: left;
    width: 100%;
    @include font-size(18);
  }

  &--button {
    .material-icons  {
      color: map.get($colors, 'green');
      position: absolute;
      top: 15px;
      right: 15px;
      @include transition(all, 0.2s, ease-in-out);
    }
  }

  &--content {
    color: lighten(map.get($colors, 'black'), 30%);
    padding: 0 25px 20px;
    margin-top: -10px;
    @include transition(all, 0.2s, ease-in-out);

    a {
      text-decoration: underline;
      color: map.get($colors, 'blue-light');
    }

    p {
      line-height: 1.4em;
      margin-bottom: 0;
      @include font-size(15);
    }
  }

  &.open {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    .faq-item--button {
      .material-icons {
        transform: rotate(-180deg);
      }
    }

    &--content {
      border-color:  map.get($colors, 'white');
    }
  }
}