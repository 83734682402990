$buttons: (
  (
    button-regular,
    inline-block,
    18,
    $font-bold,
    uppercase,
    1.2,
    11px 27px,
    center,
    7px,
    $white,
    map.get($colors,'green'),
    2px solid map.get($colors,'green'),
    map.get($colors,'green'), 
    $white,
    2px solid map.get($colors,'green')
  ),
  (
    button-regular-alt,
    inline-block,
    14,
    $font-regular,
    uppercase,
    1.2,
    10px 24px,
    center,
    7px,
    $white,
    map.get($colors,'black'),
    1px solid map.get($colors,'black'),
    map.get($colors,'green'), 
    $black,
    1px solid $black
  ),
);

@each $label,
  $display,
  $font-size,
  $font-family,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-family: $font-family;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    transition: all 0.2s ease-in-out;
    @include font-size($font-size);

    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color;
      border: $hover-border;
    }
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 50px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);
  
    span {
      background: $white;
      display: block;
      height: 6px;
      border-radius: 4px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }
  
    span:nth-child(1) {
      top: 0;
    }
  
    span:nth-child(2),
    span:nth-child(3) {
      top: 12px;
    }
  
    span:nth-child(4) {
      top: 24px;
    }  
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;
  
    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }
    
    &:hover,
    &:focus {}  
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  
    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}