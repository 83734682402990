.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: 15px;
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: color.scale(map.get($colors, 'black'), $lightness: 50%);
		border-radius: 100%;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active {
		button {
			background-color: map.get($colors, 'black');
			&:focus,
			&:hover {
				background-color: map.get($colors, 'black');
			}
		}
	}
}

#slick-home-wrapper {
	position: relative;
	.item {}

	#slick-home-arrows {

		.arrow-next,
		.arrow-prev {
			margin-top: -15px;
			position: absolute;
			top: 50%;	
		}

		.arrow-prev {
			left: -15px;
		}

		.arrow-next {
			right: -15px;
		}
		@include media-breakpoint-down(sm) {
			.arrow-prev {
				left: 0;
			}
	
			.arrow-next {
				right: 0;
			}
		}
	}

	.slick-dots {}

	.slick-slide {
		.content {
			opacity: 0;
			transform: translateY(-30px) scale(1);
			@include transition(all, 0.2s, ease-in-out, 0.5s);
		}
		&.animation {
			.content {
				opacity: 1;
				transform: translateY(0) scale(1);
			}
		}
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}
}