.reservation-step-4 {
  &--item {
    background-color: $white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 100%;
    padding: 25px 30px;
    position: relative;
  
    .title {
      border-bottom: 1px solid map.get($colors,'black');
      color: map.get($colors, 'black');
      font-family: $font-bold;
      padding-bottom: 5px;
      text-transform: uppercase;
      @include font-size(16);
    }
  
    .list {
      margin-top: 20px;
      
      li {
        display: block;
        line-height: 1.4em;
        position: relative;
        padding-left: 18px;
        word-break: break-word;
        @include font-size(14);
      
        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:before {
          content: '';
          background-color: map.get($colors,'green');
          height: 2px;
          left: 0;
          position: absolute;
          top: 9px;
          width: 8px;
        }
      }
    }  
  }
}