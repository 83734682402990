.blog {
  &--navigation {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid lighten(map.get($colors, 'black'), 72%);
    padding: 20px 30px;

    &--list {
      &--item {
        &:not(:first-child) {
          margin-top: 5px;
        }
        &--link {
          color: lighten(map.get($colors, 'black'), 15%);
          display: block;
          font-family: $font-regular;
          padding-left: 20px;
          position: relative;
          transition: all 0.1s ease-in-out;
          @include font-size(16);
    
          &:before {
            background-color: map.get($colors, 'green');
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            top: 12px;
            transition: all 0.2s ease-in-out;
            width: 8px;
          }
        
          &.active {
            color: map.get($colors, 'green') !important;
            font-weight: 600;
            &:before {
              border-color: map.get($colors, 'green') !important;
              background-color: map.get($colors, 'green') !important;
            }
          }
        
          &:hover {
            color: map.get($colors, 'green');
            padding-left: 25px;

            &:before {
              background-color: map.get($colors, 'green');
            }
          }
        }
      }
    }
  
    @include media-breakpoint-down(sm) {
      border: none;
      box-shadow: 0 0 20px rgba(0,0,0,0.1);
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &--article {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &--thumbnail {
      position: relative;
      overflow: hidden;

      &:after {
        background-color: rgba(0,0,0, 0.5);
        content: "";
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        transition: all 0.1s ease-in-out;
        width: 100%;
        z-index: 1;
      }
  
      .img-fluid {
        transition: all 0.1s ease-in-out;
      }

      &--category {
        border-radius: 0 4px 0 0;
        bottom: 0;
        background-color: $white;
        color: map.get($colors, 'green');
        font-family: $font-bold;
        left: 0;
        padding: 5px 10px;
        position: absolute;
        text-transform: uppercase;
        z-index: 2;
        @include font-size(12);
      }
    } 

    &:hover {
      color: lighten(map.get($colors, 'black'), 30%);
      .blog--article--thumbnail {
        &:after {
          opacity: 0;
        }
        
        .img-fluid {
          transform: scale(1.1);
        }
      }
    }    
  }
}


// // Blog :: Article list
// .category-label {
//   display: inline-block;
//   color: $bl-a;
//   background-color: $wh-a;
//   font-weight: 600;
//   border: 1px solid $wh-a;
//   letter-spacing: 0.05em;
//   margin-bottom: px(20);
//   font-size: px(10);
//   padding: 2px 6px 2px;
//   border-radius: 3px;
//   @include media-breakpoint-down(sm) {
//     margin-bottom: px(10);
//   }
// }

// .item-blog {
//   position: relative;
//   height: 100%;
//   display: block;
//   padding: 0;
//   overflow: hidden;
//   background-color: $wh-a;
//   border-radius: 8px;
//   box-shadow: 0 0 10px rgba(0,0,0,0.15);
//   transition: all 0.2s ease-in-out;

//   .img-wp {
//     position: relative;
//     overflow: hidden;
//   }

//   .img-wp:after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0,0,0,0.15);
//     transition: all 0.2s ease-in-out;
//   }

//   .img-wp .img-fluid {
//     transition: all 0.2s ease-in-out;
//   }

//   .content {
//     padding: 20px;
//   }

//   .category-blog {
//     position: absolute;
//     top: 17px;
//     z-index: 10;
//     right: 20px;
//   }

//   .title-blog {
//     font-size: px(18);
//     font-family: 'museo500';
//     display: block;
//     color: $bl-a;
//     margin-bottom: px(8);
//     line-height: 1.1em;
//   }

//   .desc {
//     margin-top: px(10);
//     font-size: px(14);
//     margin-bottom: 0;
//     line-height: 1.4em;
//   }

//   .btn-blog {
//     color: map.get($colors, 'green');
//     margin-top: 10px;
//     font-weight: 600;
//     text-align: right;
//     display: block;
//   }

//   &:focus .img-wp .img-fluid,
//   &:hover .img-wp .img-fluid {
//     transform: scale(1.1);
//   }

//   &:focus .img-wp:after,
//   &:hover .img-wp:after {
//     background-color: rgba(0,0,0,0.1);
//   }
//   @include media-breakpoint-down(sm) {
//     .title {
//       font-size: px(20);
//     }
//   }
// }